import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { isDefined } from "@clipboard-health/util-ts";
import {
  IonAlert,
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText,
  IonToolbar,
} from "@ionic/react";
import { Button } from "@mui/material";
import { CREATE_HCP_DOCUMENT, DELETE_HCP_DOCUMENT } from "@src/app/documents/gql";
import { REFERENCES_REQUIREMENT_ID } from "@src/app/professionalReferences/constants";
import { useCheckProfessionalReferenceLDFlag } from "@src/app/professionalReferences/hook/useCheckProfessionalReferenceLDFlag";
import { OnboardingRouterPath } from "@src/app/routing/constant/onboardingRoute";
import { GET_REQUIREMENTS_STATUS } from "@src/appV2/Accounts/Documents/api/deprecatedDoNotUseGraphQlCH23034";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import { Requirement } from "@src/appV2/Accounts/Documents/types";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  StripeIdentityVerificationFlowStatus,
  startStripeIdentitySession,
} from "@src/appV2/Stripe";
import { Worker } from "@src/appV2/Worker/api/types";
import {
  DEFAULT_ERROR_MESSAGE,
  DRIVING_LICENSE_STATE_ID_FILE_NAME,
  DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_KEY,
  DRIVING_LICENSE_STATE_ID_PLACEHOLDER_ROOT_KEY,
  DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID,
  PERSONAL_ID,
  STRIPE_ERROR_MESSAGE,
} from "@src/constants/stripeDocumentAutoVerification.constants";
import { getVerificationSessionClientSecret } from "@src/utils/stripeUtils";
import { chevronForwardOutline } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { AgentDocumentUploader } from "./AgentDocumentUploader";
import Alert from "./Alert";
import { fireVerifyDocumentZapierHook, updateAgentData } from "./api";
import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { ErrorAlert } from "./types";
import { USER_EVENTS } from "../../../constants";
import { CHECKR_REQUIREMENT_ID } from "../../../constants";
import { customCheckmark } from "../../../icons/customCheckmark";
import { updateCache, uploadSelectedDocument } from "../../documents/api";
import { SelectedFile } from "../../shiftSignature/timecard/model";
import { constructPDFFromMultipleImages } from "../../utils/constructPDFFromMultipleImages";
import { isImage } from "../../utils/isImage";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { logOnboardingError } from "../util/logging";
import { fireOnboardingSegmentEvent } from "../util/segment";

export function AgentUploadDocuments(props: OnBoardingComponentProps) {
  const { agent, nextStagePath } = props;
  const { showErrorToast } = useToast();
  const [documents, setDocuments] = useState<Requirement[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<string | null>();
  const [uploadedDocuments, setUploadedDocuments] = useState<string[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<{
    [key: string]: SelectedFile[];
  }>({});
  const [errorAlert, setErrorAlert] = useState<ErrorAlert>({ hasError: false });
  const [uploadingDocuments, setUploadingDocuments] = useState<string[]>([]);
  const [stripeLoading, setStripeLoading] = useState(false);

  const { data, loading } = useQuery(GET_REQUIREMENTS_STATUS, {
    variables: { hcpId: agent?.userId },
  });
  const client = useApolloClient();
  const [showContinueAlert, setShowContinueAlert] = useState<boolean>(false);
  const [showFileUploadAlert, setShowFileUploadAlert] = useState<string>("");
  const history = useHistory();
  const [createHcpDocument] = useMutation(CREATE_HCP_DOCUMENT);
  const [deleteHcpDocument] = useMutation(DELETE_HCP_DOCUMENT);

  const isAutoVerificationDocumentCleanupEnabled = useCbhFlag(
    CbhFeatureFlag.AUTO_VERIFICATION_DOCUMENT_CLEANUP_ENABLED,
    { defaultValue: false }
  );

  const isCheckrInvitesEnabled = useCbhFlag(CbhFeatureFlag.ENABLE_CHECKR_PILL_STATUS, {
    defaultValue: true,
  });

  const { isProfessionalReferencesEnabled } = useCheckProfessionalReferenceLDFlag();

  const SEGMENT_ONBOARDING_CONTEXT = "onboarding";

  useEffect(() => {
    if (data) {
      const {
        hcpRequirementStatus: { pending = [], completed = [], requirements = [] },
      } = data;
      let missingAgentDocuments = requirements
        .filter(
          (requirement: Requirement) => requirement.visibleToHCP && requirement.level !== "HCF"
        )
        .filter(
          (requirement: Requirement) =>
            requirement.requirementWorkflowType !== RequirementWorkflowType.SIGN_WITH_HELLOSIGN &&
            requirement.requirementWorkflowType !== RequirementWorkflowType.COMPOSITE
        );
      if (isCheckrInvitesEnabled) {
        missingAgentDocuments = missingAgentDocuments.filter(
          (requirement: Requirement) => requirement?.reqId !== CHECKR_REQUIREMENT_ID
        );
      }

      setDocuments(missingAgentDocuments);
      const allPreviouslyUploadedDocuments = [...pending, ...completed];
      const validUploadedDocuments = missingAgentDocuments
        .filter((document: Requirement) => allPreviouslyUploadedDocuments.includes(document.reqId))
        .map((document: Requirement) => document.reqId);
      setUploadedDocuments(validUploadedDocuments);
    }
  }, [data, isCheckrInvitesEnabled]);

  const handleStageUpdate = async (): Promise<boolean> => {
    try {
      await updateAgentData({
        stage: ONBOARDING_STAGES.DOCUMENTS,
      });
      return true;
    } catch (error) {
      setErrorAlert({ hasError: true, reason: error?.message });
      return false;
    }
  };

  const onUploadingDocument = (requirement: Requirement) => {
    setUploadingDocuments([...(uploadingDocuments as string[]), requirement.reqId as string]);
    const newUploadedDocuments = uploadedDocuments.filter(
      (document) => document !== requirement.reqId
    );
    setUploadedDocuments(newUploadedDocuments);
  };

  const onDocumentUploaded = (requirement: Requirement) => {
    setUploadedDocuments([...(uploadedDocuments as string[]), requirement.reqId as string]);
    const newUploadingDocuments = uploadingDocuments.filter(
      (document) => document !== requirement.reqId
    );
    setUploadingDocuments(newUploadingDocuments);
    // Close the panel once the document has been uploaded
    setSelectedDocument(null);
  };

  const onErrorUploadingDocument = (requirement: Requirement) => {
    const newUploadingDocuments = uploadingDocuments.filter(
      (document) => document !== requirement.reqId
    );
    const newUploadedDocuments = uploadedDocuments.filter(
      (document) => document !== requirement.reqId
    );
    setUploadingDocuments(newUploadingDocuments);
    setUploadedDocuments(newUploadedDocuments);
    showErrorToast("Unable to upload document, please try again!");
  };

  const onNext = async () => {
    try {
      const isStageUpdated = await handleStageUpdate();
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.FINISHED_SUBMITTING_DOCUMENTS, {
        hcpId: agent?.userId as string,
        email: agent?.email as string,
      });
      if (!isStageUpdated) {
        return;
      }
      if (documents.length && uploadedDocuments.length !== documents.length) {
        setShowContinueAlert(true);
      } else {
        history.push(nextStagePath as string);
      }
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        message: error?.message,
      });
    }
  };

  const onSelectedFile = (documentId: string) => (file: SelectedFile) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [documentId]: [...(selectedFiles[documentId] || []), file],
    }));
  };

  const clearSelectedFiles = (documentId: string) => () => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [documentId]: [],
    }));
  };

  const toggleSelectedDocument = (documentId: string) => () => {
    const selectedDocumentObj = documents.find((document) => document._id === documentId);

    if (
      selectedDocumentObj?.reqId === REFERENCES_REQUIREMENT_ID &&
      isProfessionalReferencesEnabled
    ) {
      history.push(OnboardingRouterPath.ONBOARDING_PROFESSIONAL_REFERENCES);
    } else if (
      !uploadedDocuments.includes(selectedDocumentObj?.reqId as string) &&
      selectedDocument &&
      (selectedFiles[selectedDocument] || []).length > 0
    ) {
      setShowFileUploadAlert(documentId);
    } else {
      setSelectedDocument(selectedDocument === documentId ? null : documentId);
    }
  };

  const handleFileUpload = async (document: Requirement) => {
    const hcpId = agent?.userId;
    onUploadingDocument(document);
    try {
      const documentSelectedFiles = selectedFiles[document._id] || [];
      const canUploadMultiplePages = isImage(documentSelectedFiles[0]?.type);
      let documentFile;
      if (canUploadMultiplePages && documentSelectedFiles.length > 1) {
        const { fileBlob } = await constructPDFFromMultipleImages(documentSelectedFiles);
        if (!fileBlob) {
          return;
        }
        documentFile = { fileBlob, type: "PDF" };
      } else {
        [documentFile] = documentSelectedFiles;
      }
      const selectedRequirement = { requirement: document };
      const { name, fileStorageRootFolder, fileStorageFileKey } = await uploadSelectedDocument(
        documentFile,
        selectedRequirement,
        client,
        hcpId
      );
      await createHcpDocument({
        variables: {
          subType: document.subType,
          name,
          hcpId,
          fileStorageRootFolder,
          fileStorageFileKey,
          uploadedFor: document.reqId,
          covidTest: null,
        },
        update: updateCache({ hcpId, selectedRequirement, covidTest: null }),
      });

      onDocumentUploaded(document);
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ADDED_DOCUMENT_DURING_SIGNUP, {
        hcpId: agent?.userId as string,
        requirementId: document.reqId,
      });
      fireVerifyDocumentZapierHook(agent as Worker, {
        document,
      });
    } catch (error) {
      const eventDetails = {
        context: "handleFileUpload",
        requirement: document?.name,
        id: document?._id,
        reqId: document?.reqId,
      };
      logOnboardingError(
        ONBOARDING_STAGES.DOCUMENTS,
        (error as Error).message,
        agent?.userId,
        eventDetails
      );
      onErrorUploadingDocument(document);
    }
  };

  // Used to verify State Id / Driving License Document using stripe.
  // It gets a clientSecret from the identity-doc-autoverification-service
  // and uses it to show Stripe modal to the user, where they can upload the document.
  // further processing of documents is handled in identity-doc-autoverification-service
  const handleStripeUpload = async (document: Requirement) => {
    const hcpId = agent?.userId;
    let documentId;
    let verificationSessionId;

    try {
      setStripeLoading(true);
      logEvent(USER_EVENTS.STRIPE_IDENTITY_FLOW_BEGUN, {
        context: SEGMENT_ONBOARDING_CONTEXT,
      });
      if (!hcpId) {
        throw new Error(DEFAULT_ERROR_MESSAGE);
      }

      const response = await createHcpDocument({
        variables: {
          subType: document.subType,
          name: DRIVING_LICENSE_STATE_ID_FILE_NAME,
          hcpId,
          fileStorageRootFolder: DRIVING_LICENSE_STATE_ID_PLACEHOLDER_ROOT_KEY,
          fileStorageFileKey: DRIVING_LICENSE_STATE_ID_PLACEHOLDER_FILE_KEY,
          uploadedFor: document.reqId,
          covidTest: null,
        },
      });
      documentId = response?.data?.createHcpDocument?._id;

      const verificationResult = await getVerificationSessionClientSecret(hcpId, documentId);
      if (verificationResult?.error || !isDefined(verificationResult.verificationSession)) {
        throw new Error(verificationResult?.error);
      }
      const { verificationSessionId, clientSecret, ephemeralKeySecret, id } =
        verificationResult.verificationSession;

      if (
        !isDefined(verificationSessionId) ||
        !isDefined(clientSecret) ||
        !isDefined(ephemeralKeySecret) ||
        !isDefined(id)
      ) {
        throw new Error(STRIPE_ERROR_MESSAGE);
      }

      const stripeVerificationStatus = await startStripeIdentitySession({
        clientSecret,
        verificationSessionId,
        ephemeralKeySecret,
      });

      if (stripeVerificationStatus !== StripeIdentityVerificationFlowStatus.COMPLETED) {
        throw new Error("Stripe Identity Verification Failure");
      }

      onDocumentUploaded(document);
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.ADDED_DOCUMENT_DURING_SIGNUP, {
        hcpId: agent?.userId as string,
        requirementId: document.reqId,
      });
      logEvent(USER_EVENTS.STRIPE_IDENTITY_FLOW_COMPLETED, {
        context: SEGMENT_ONBOARDING_CONTEXT,
        verificationSessionId,
        success: true,
      });
    } catch (error) {
      // delete the document in case of any error, only if isAutoVerificationDocumentCleanupEnabled is enabled.
      if (isAutoVerificationDocumentCleanupEnabled && documentId) {
        await deleteHcpDocument({
          variables: {
            _id: documentId,
          },
        });
      }
      showErrorToast(`${error.message}`);
      const eventDetails = {
        context: "handleFileUpload",
        requirement: document?.name,
        id: document?._id,
        reqId: document?.reqId,
      };
      logOnboardingError(
        ONBOARDING_STAGES.DOCUMENTS,
        (error as Error).message,
        agent?.userId,
        eventDetails
      );
      logEvent(USER_EVENTS.STRIPE_IDENTITY_FLOW_COMPLETED, {
        context: SEGMENT_ONBOARDING_CONTEXT,
        verificationSessionId: verificationSessionId ?? "",
        success: false,
      });
      onErrorUploadingDocument(document);
    } finally {
      setStripeLoading(false);
    }
  };

  function closeAlert() {
    setErrorAlert({ hasError: false });
  }
  const getDocumentName = useCallback((document: Requirement): string => {
    if (String(document.reqId) === DRIVING_LICENSE_STATE_ID_REQUIREMENT_ID) {
      return PERSONAL_ID;
    }
    return document.name;
  }, []);
  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/home/agentSignupInfo2" mode="ios" color="dark" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding" scrollEvents={true}>
        <IonAlert
          isOpen={showContinueAlert}
          onDidDismiss={() => setShowContinueAlert(false)}
          header={`${documents.length - uploadedDocuments.length} Documents missing`}
          message={`If you don’t have these documents handy, that’s ok.
          Are you done adding documents for now?`}
          buttons={[
            {
              text: "Add more",
              role: "cancel",
            },
            {
              text: "I'm done",
              handler: () => {
                fireOnboardingSegmentEvent(
                  ONBOARDING_SEGMENT_EVENT_NAMES.FINISHED_SUBMITTING_DOCUMENTS,
                  {
                    hcpId: agent?.userId?.toString() ?? "",
                    email: agent?.email as string,
                  }
                );
                history.push(nextStagePath as string);
              },
            },
          ]}
        />

        <IonAlert
          isOpen={!!showFileUploadAlert}
          onDidDismiss={() => setShowFileUploadAlert("")}
          header={`Upload this document?`}
          message={`Are you sure you would like to continue without uploading this document?`}
          buttons={[
            {
              text: "Skip",
              handler: () => {
                setSelectedDocument(
                  selectedDocument === showFileUploadAlert ? null : showFileUploadAlert
                );
              },
            },
            {
              text: "Finish and upload",
              handler: () => {
                const selectedDocumentObj = documents.find(
                  (document) => document._id === (selectedDocument as string)
                );

                handleFileUpload(selectedDocumentObj as Requirement);
              },
            },
          ]}
        />

        <Alert isOpen={errorAlert.hasError} closeAlert={closeAlert} reason={errorAlert?.reason} />

        <div className="signup-content content-layout">
          <div className="form-container">
            <form>
              <IonRow>
                <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                  <div className="form-heading">
                    <h4>Add required documents</h4>
                    <p>
                      If you don’t have everything with you right now, don’t worry, you can always
                      add them later.
                    </p>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12">
                  {loading && (
                    <IonSpinner
                      class="ion-margin-start"
                      name="lines"
                      data-testid="loading-indicator"
                    />
                  )}

                  {!loading && documents.length === 0 && (
                    <IonText>
                      <h4>No documents to upload</h4>
                    </IonText>
                  )}
                  {documents.map((document: Requirement) => (
                    <div
                      id="uploadDocument"
                      key={document._id}
                      className={`form-item-wrapper shadowedCard document-card
                      ${selectedDocument === document._id ? ` selected` : ""}`}
                    >
                      <IonItem
                        lines="none"
                        className="document-item"
                        onClick={toggleSelectedDocument(document._id)}
                      >
                        <IonLabel className="aqf-label">{getDocumentName(document)}</IonLabel>
                        {uploadedDocuments.includes(document.reqId as string) && (
                          <IonIcon
                            id="uploadCheck"
                            icon={customCheckmark}
                            slot="end"
                            mode="ios"
                            color="medium"
                          />
                        )}
                        <IonIcon
                          id="chevronButton"
                          icon={chevronForwardOutline}
                          slot="end"
                          mode="ios"
                          color="medium"
                          className={selectedDocument === document._id ? `active` : ""}
                        />
                      </IonItem>

                      <div className="">
                        {selectedDocument === document._id && (
                          <AgentDocumentUploader
                            agent={agent as Worker}
                            document={document}
                            uploading={uploadingDocuments.includes(document.reqId as string)}
                            uploaded={uploadedDocuments.includes(document.reqId as string)}
                            selectedFiles={selectedFiles[document._id]}
                            setSelectedFiles={onSelectedFile(document._id)}
                            clearSelectedFiles={clearSelectedFiles(document._id)}
                            handleFileUpload={handleFileUpload}
                            handleStripeUpload={handleStripeUpload}
                            stripeLoading={stripeLoading}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </IonCol>
              </IonRow>
            </form>
          </div>

          <div className="signupform-footer footer-container">
            <Button
              variant="contained"
              fullWidth
              color="secondary"
              onClick={onNext}
              disabled={loading}
              sx={{
                // TODO: Remove these styles. These should be handled by the layout. (Using original styles)
                marginTop: 2,
                marginBottom: 1,
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </IonContent>
    </StyledIonPage>
  );
}
